<template>
  <div>
    <h1>Cancellations</h1>

    <table v-if="usersWhoCancelled" class="table-fixed">
      <tr>
        <th
          @click="orderUsers"
          class="text-left cursor-pointer"
          :class="
            orderDirection === 'desc'
              ? 'bg-green-500 rounded-t-lg text-white'
              : 'bg-red-500 rounded-t-lg text-white'
          "
        >
          Subscription End Date
        </th>
        <th class="text-left">Username</th>
        <th class="text-center cursor-pointer">
          Subscription Tier Name
        </th>
        <th class="text-center cursor-pointer">
          Subscription Interval
        </th>
        <th class="text-center cursor-pointer">
          DM
        </th>
      </tr>
      <tr v-for="user of usersWhoCancelled" :key="user.uid">
        <td class="whitespace-no-wrap">
          {{ formatDate(user.subscriptionEndDate.toDate()) }}
        </td>
        <td dir="auto">
          <a :href="`https://twitter.com/${user.username}`" target="_blank">{{ user.username }}</a>
        </td>
        <td class="text-center">{{ user.subscriptionTierName }}</td>
        <td class="text-center">
          {{ user.subscriptionInterval }}
        </td>
        <td class="text-center">
          <a
            :href="`https://twitter.com/messages/compose?recipient_id=${user.twitterId}`"
            target="_blank"
          >
            <inline-svg src="/img/icons/chat.svg"></inline-svg>
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import 'moment-timezone';
  import dao from '@/dao';
  import lodash from 'lodash';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
    data() {
      return {
        usersWhoCancelled: null,
        orderDirection: 'desc',
      };
    },
    async mounted() {
      const response = await dao.userProfile.getUsersWhoCancelled();
      this.usersWhoCancelled = response.docs.map((user) => user.data());
    },
    methods: {
      formatDate(date) {
        const timezone =
          this.userProfile && this.userProfile.timezone
            ? this.userProfile.timezone
            : moment.tz.guess();
        return moment.tz(date, timezone).format('MMM D Y');
      },
      orderUsers() {
        this.orderDirection = this.orderDirection === 'desc' ? 'asc' : 'desc';

        this.usersWhoCancelled = lodash.orderBy(
          this.usersWhoCancelled,
          'subscriptionEndDate',
          this.orderDirection
        );
      },
    },
  };
</script>
